import React, { Component } from 'react'
import Link from 'gatsby-link'
import PropTypes from 'prop-types'
import axios from 'axios'

export class CaseItem extends Component {
  state = {
    video_src: '',
    controls: false,
    loading: false,
    muted: true,
    height: 'auto',
  }

  constructor(props) {
    super(props);
    this._play = this._play.bind(this)
    this._pause = this._pause.bind(this)
  }

  componentDidMount() {
    let { acf, src, local, autoplay, onscroll } = this.props;
    
    // If video is local
    if (local) {
      this.setState({ video_src: src })
    } else if (acf.video_loop) {
      console.log(acf.video_loop)
      this._loadVideo(acf.video_loop)
    }

    this.setState({ height: this.refs.cont.offsetWidth * (9 / 16) })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.acf.video_loop !== this.props.acf.video_loop && this.props.acf.video_loop) {
      this._loadVideo(this.props.acf.video_loop)
    }
  }

  _loadVideo(video_loop) {
    this.setState({ loading: true, video_src: '', controls: false })
    // Then video must be from youtube
    axios(`https://api.codedrips.com/youtube/${video_loop}`)
      .then((response) => {
        let video_src = response.data.url
        this.setState({ video_src, loading: false });

      })
      .catch(error => {});

  }

  _play() {
    let { video } = this.refs
    if (video && video.readyState === 4) {
      video.play()
    }
  }

  _rewind() {
    let { video } = this.refs;
    if (!video) return
    video.pause()
    video.currentTime = 0
    video.load()
  }

  _pause() {
    let { video } = this.refs;
    if (video.readyState === 4) {
      video.pause();
      this.setState({ controls: false });
    }
  }

  render() {

    let linkProps = {
      to: `/case-study/${this.props.post_name}`,
      className: 'r3__case-item',
      style: { height: this.state.height },
      onMouseOver: (event) => {
        this._play()
      },
      onMouseLeave: (event) => {
        this._rewind()
      }
    }

    return (
      <Link {...linkProps}>
        { this.props.acf && this.props.acf.video_loop ? 
          <div className='r3__video' ref='cont'>
            <video 
              ref="video" 
              controls={this.props.controls && this.state.controls}
              poster={this.props.acf.video_placeholder.source_url}
              muted={this.state.muted}
              loop={this.props.loop}
              autoPlay={this.props.autoplay}
              playsInline
              src={this.state.video_src}>
            </video>
          </div> :
          <div className="r3__thumb" ref='cont' style={{ backgroundImage: `url(${this.props.acf.video_placeholder.source_url})` }}></div>
        }
        <div className="r3__thumb-content">
          <h4>{ this.props.post_title }</h4>
          <span>Launch Case Study →</span>
        </div>
        <div className='r3__case-item__gradient'></div>
      </Link>
    );
  }
}

CaseItem.propTypes = {
  showTitle: PropTypes.bool,
  local: PropTypes.bool,
  autoplay: PropTypes.bool,
  onscroll: PropTypes.bool,
  loop: PropTypes.bool,
  controls: PropTypes.bool,
  hover: PropTypes.bool,
  acf: PropTypes.object,
};

CaseItem.defaultProps = {
  showTitle: false,
  acf: {video_placeholder: {}},
}

export default CaseItem 
