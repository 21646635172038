import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import Reveal from 'react-reveal/Reveal'
import Slider from "react-slick"

import TemplateWrapper from '../components/layout'
import Title from '../components/title'
import Player from '../components/Video/player'
import CaseItem from '../components/Video/case-item'
import Logo from '../components/logo'

class LandingPage extends Component {

  render() {

    let data = this.props.data.wordpressPage
    let categories = this.props.data.allWordpressWpWorkCategory.edges

    let { featured_work } = data.acf
    featured_work = featured_work.length ? featured_work[0] : false
    featured_work.title = featured_work.post_title
    featured_work.slug = featured_work.post_name
    featured_work.video_url = featured_work.acf.video_url
    featured_work.video_placeholder = featured_work.acf.video_placeholder ? featured_work.acf.video_placeholder.source_url : null

    let settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      dots: false,
      cssEase: 'ease',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
    }

    return (
      <TemplateWrapper {...this.props}>
        <div>
          { data.yoast_meta &&
            <Helmet>
              <title>{ data.yoast_meta.yoast_wpseo_title }</title>
              <meta name="description" content={ data.yoast_meta.yoast_wpseo_metadesc } />
            </Helmet>
          }
          <div className="r3__landing r3--dark">
            <Logo />
          </div>

          <div className="r3__section r3__section--relative">
            <Reveal effect="scrollDown" duration={6000}>
              <div className="r3__scroll"></div>
            </Reveal>
            <div className="r3__content">
              <Reveal effect="fadeInLeft">
                <Title text={data.acf.intro_title} />
              </Reveal>
              <Reveal effect="fadeInUp">
                <div className="r3__narrow">
                    <div dangerouslySetInnerHTML={{__html: data.acf.intro_content}} />
                    { data.acf.intro_button_link &&
                      <Link className="btn" to={ data.acf.intro_button_link }>{ data.acf.intro_button_text } →</Link>
                    }
                </div>
              </Reveal>
            </div>
          </div>

          { data.acf.case_studies.length &&
            <div>
              <div className="r3__section r3--half">
                <div className="r3__content r3__case-item__container">
                  { data.acf.case_studies.map((el, i) => <CaseItem {...el} key={i} />) }
                </div>
              </div>
              <div className="r3__section r3--dark">
                <Reveal effect="fadeInUp">
                  <div className="r3__content r3__narrow" dangerouslySetInnerHTML={{__html: data.acf.case_studies_content}} />
                </Reveal>
              </div>
            </div>
          }


          <div className="r3__section">
            <div className="r3__content">
              <Reveal effect="fadeInLeft">
                <Title text={data.acf.our_work_title} />
              </Reveal>
              <Reveal effect="fadeInUp">
                <div className="r3__narrow">
                  <div dangerouslySetInnerHTML={{__html: data.acf.our_work_content}}></div>
                  <h6>{ data.acf.scrolling_text }</h6>
                </div>
              </Reveal>
            </div>
          </div>


          <div className="r3__section r3--dark">
            { data.services &&
              <div className="r3__content">
                <Reveal effect="fadeInLeft">
                  <Title text={data.acf.services_title} />
                </Reveal>
                <Reveal effect="fadeInUp">
                  <div className="r3__narrow">
                    <div dangerouslySetInnerHTML={{__html: data.acf.services_content}}></div>
                  </div>
                </Reveal>
              </div>
            }

            <div className="r3__content">
              { featured_work &&
                <Player showTitle={true} {...featured_work} controls={true} />
              }

              <ul className="r3__categories">
              { categories.slice(0, 4).map((el, i) => {
                if (el.count === 0) return false;
                return (
                  <li key={i}>
                    <Link
                      to={`/work/${el.node.slug}`}
                      dangerouslySetInnerHTML={{__html: el.node.name}}>
                    </Link>
                  </li>
                )
              }) }
                <li><Link to={'/work'}>View all →</Link></li>
              </ul>
              <Link to={'/work'} className='small-link'>More →</Link>
            </div>
          </div>


          { data.acf.clients.length &&
            <div className="r3__section">
              <div className="r3__content">
                <Reveal effect="fadeInLeft">
                  <div>
                    <h3>Who we create for.</h3>
                    <div className="r3__partners">
                    <Slider {...settings} slidesToShow={5} slidesToScroll={5}>
                      { data.acf.clients.map((el, i) => <a href={el.link} key={i} target="_blank"><img src={el.logo.source_url} alt={el.link} /></a>) }
                    </Slider>
                    </div>
                  </div>
                </Reveal>
              </div>
            </div>
          }

          { data.acf.awards &&
            <div className="r3__section">
              <div className="r3__content">
                <Reveal effect="fadeInLeft">
                  <div>
                    <h3>{data.acf.awards_title}</h3>
                    <div className="r3__partners">
                    <Slider {...settings}>
                      { data.acf.awards.map((el, i) => <a href={el.link} key={i} target="_blank"><img src={el.image.source_url} alt={el.link} /></a>) }
                    </Slider>
                    </div>
                  </div>
                </Reveal>
              </div>
            </div>
          }

        </div>
      </TemplateWrapper>
    )
  }
}

export default LandingPage

export const pageQuery = graphql`
  query homeQuery {
    allWordpressWpWorkCategory {
      edges {
        node {
          name
          slug
        }
      }
    }
    wordpressPage(title: {eq: "Home"}) {
      id
      acf {
        intro_title
        intro_video
        intro_content
        intro_button_text
        intro_button_link

        case_studies_content
        case_studies {
          post_title
          post_name
          acf {
            video_url
            video_loop
            video_placeholder {
              source_url
            }
          }
        }

        services_title
        services_content
        featured_work {
          post_title
          post_name
          acf {
            video_url
            video_placeholder {
              source_url
            }
          }
        }

        our_work_title
        our_work_content
        scrolling_text

        awards_title
        awards {
          link
          image {
            source_url
          }
        }
        clients {
          link
          logo {
            source_url
          }
        }
      }

      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
  }
`
