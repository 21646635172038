import React, { Component } from 'react'
import { withPrefix } from 'gatsby-link'
import * as THREE from 'three';
import ripple from '../assets/images/icon/Ripple-1s-200px.svg'
const OBJLoader = require('three-obj-loader');
OBJLoader(THREE);

class Logo extends Component {
  state = {
    loading: true,
  }

  componentDidMount() {
    var video = this.refs.video;

    var container = this.refs.container;
    var camera, scene, renderer;
    var videoImage, videoImageContext, videoTexture;
    var mouseX = 0, mouseY = 0, mouseZ = 0;
    var windowHalfX = container.offsetWidth / 2;
    var windowHalfY = container.offsetHeight / 2;

    video.addEventListener('canplaythrough', function() {
      video.play();//this second play is needed for Android 4.0 only
    }, false);

    container.addEventListener('click', function() {
      video.play();
    }, false);

    video.addEventListener('loadeddata', event => {
      // Video is loaded and can be played
      this.setState({ loading: false })

    }, false);

    video.src = withPrefix('/videos/loop.mp4');
    video.load(); // must call after setting/changing source

    video.loop = false;
    video.addEventListener('ended', function() {
      video.currentTime=0.1;
      video.play();
    }, false)

    const success = init();
    if (!success) return; 
    animate();

    function init() {
      camera = new THREE.PerspectiveCamera( 45, container.offsetWidth / container.offsetHeight, 1, 2000 );
      camera.position.z = 250;

      // scene
      scene = new THREE.Scene();
      var ambientLight = new THREE.AmbientLight( 0xcccccc, 0.4 );
      //scene.add( ambientLight );
      var pointLight = new THREE.PointLight( 0xffffff, 0.8 );
      //camera.add( pointLight );
      scene.add( camera );
      // texture
      var manager = new THREE.LoadingManager();
      manager.onProgress = function ( item, loaded, total ) {
        //console.log( item, loaded, total );
      };
      // model
      var onProgress = function ( xhr ) {
        if ( xhr.lengthComputable ) {
          var percentComplete = xhr.loaded / xhr.total * 100;
          //console.log( Math.round(percentComplete, 2) + '% downloaded' );
        }
      };
      var onError = function ( xhr ) {};
      var loader = new THREE.OBJLoader( manager );

      videoImage = document.createElement( 'canvas' );
      videoImage.width = 1024; //video.videoWidth;
      videoImage.height = 1024; //video.videoHeight;
      videoImageContext = videoImage.getContext( '2d' );
      // background color if no video present
      videoImageContext.fillStyle = '#000000';
      //videoImageContext.fillRect( 0, 0, videoImage.width, videoImage.height );
      videoImageContext.fillRect( 0, 0, 1024, 1024 );
      videoTexture = new THREE.Texture(
         videoImage,
         THREE.CubeRefractionMapping,
         //THREE.ClampToEdgeWrapping,
         //THREE.ClampToEdgeWrapping,
         THREE.RepeatWrapping,
         THREE.RepeatWrapping,
         //THREE.MirroredRepeatWrapping,
         //THREE.MirroredRepeatWrapping,
      );
      videoTexture.minFilter = THREE.LinearFilter;
      videoTexture.magFilter = THREE.LinearFilter;
      videoTexture.offset = new THREE.Vector2( 0.5, 0.5 );

      var movieMaterial = new THREE.MeshBasicMaterial({
        map: videoTexture,
        overdraw: 0,
        side:THREE.FrontSide
      });

      let scale = 250
      let xShift = 0
      let logoObj = withPrefix('/obj/FLAT_SHORT.obj')
      if (window.outerWidth > 768) {
        scale = 300
        xShift = 20
        logoObj = withPrefix('/obj/HORIZONTAL_SHORT.obj')
      }

      loader.load( logoObj, function ( object ) {
        object.scale.set( scale, scale, scale );

        object.traverse( function ( child ) {
          if ( child instanceof THREE.Mesh ) {
            child.material = movieMaterial;
            //child.material.map = texture;
          }
        });
        object.position.x -= xShift;
        scene.add( object );
      }, onProgress, onError );
      //
      try {
        renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      } catch (e) {
        return false;
      }
      renderer.setPixelRatio( window.devicePixelRatio );
      renderer.setSize( container.offsetWidth, container.offsetHeight );
      renderer.setClearColor( 0x000000, 0 );
      container.appendChild( renderer.domElement );
      document.addEventListener( 'mousemove', onDocumentMouseMove, false );

			if (window.DeviceMotionEvent !== undefined) {
				window.addEventListener("devicemotion", accelerometerUpdate, true);
			}
      //
      window.addEventListener( 'resize', onWindowResize, false );

      return true;
    }
    function onWindowResize() {

      windowHalfX = container.offsetWidth / 2;
      windowHalfY = container.offsetHeight / 2;
      camera.aspect = container.offsetWidth / container.offsetHeight;
      camera.updateProjectionMatrix();
      renderer.setSize( container.offsetWidth, container.offsetHeight );
    }
    function onDocumentMouseMove( event ) {
      mouseX = ( event.clientX - windowHalfX ) / 4;
      mouseY = ( event.clientY - windowHalfY ) / 4;
    }
		function accelerometerUpdate(event) {
			var aX = event.accelerationIncludingGravity.x*25;
			var aY = event.accelerationIncludingGravity.y*10;
			//var aZ = event.accelerationIncludingGravity.z*10;

      mouseX = ( aX / 120 * windowHalfX );
      mouseY = ( aY / 180 * windowHalfY );
		}
    function animate() {
      requestAnimationFrame( animate );
      render();
    }
    function render() {
      if ( video.readyState === video.HAVE_ENOUGH_DATA ) {
        //videoImageContext.drawImage( video, w, h, w*3, h*3, 0, 0, video.videoWidth, video.videoHeight );
        videoImageContext.drawImage( video, 612, 0, 1024, 1024, 0, 0, 1024, 1024 );
        if ( videoTexture )
          videoTexture.needsUpdate = true;
      }
      camera.position.x += ( mouseX - camera.position.x ) * .05;
      camera.position.y += ( mouseY - camera.position.y ) * .05;
      camera.lookAt( scene.position );
      renderer.render( scene, camera );
    }
  }

  render() {
    return (
      <div className='r3__webgl' ref='container'>
        { this.state.loading && <img className='r3__webgl__loading' src={ripple} /> }
        <video ref='video' loop={'loop'} muted={'muted'} autoPlay={'autoplay'} playsInline={'playsinline'} />
      </div>
    )
  }

}

export default Logo
